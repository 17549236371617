import React from "react";
import Layout from "../containers/layout";
import ContentCard from '../components/ContentCardTest/ContentCard';
import { graphql, Link } from 'gatsby'
import BasePortableText from '@sanity/block-content-to-react'
import SEO from '../components/seo';

export const query = graphql`
  {
    site: sanityAbout(_id: {regex: "/(drafts.|)about/"}) {
      pageIntroduction {
        subTitle
        title
        description
        buttonText
        buttonLink
        thumbNail {
          asset {
            url
          }
        }
        imageEffect
      }
      bodyThumbNail {
        asset {
          url
        }
      }
      CTACompact {
        subTitle,
        title,
        description,
        buttonText,
        buttonLink
      }
      _rawBody(resolveReferences: {maxDepth: 5})
    }

    blogs: allSanityBlog {
        edges {
          node {
            blogIntroduction {
              title
              description
              publishedAt(formatString: "D MMMM, YYYY")
              thumbNail {
                asset {
                  url
                }
              }
            }
            id
            title
            slug {
              current
            }
          }
        }
    }
  }
`

class OverMij extends React.Component {
  constructor(queryData) {
    super(queryData);
    const {data} = queryData;

    this.data = data;
  }
  render() {

    const { pageIntroduction } = this.data.site;
    const blogsArray = this.data.blogs.edges;
    const sortedBlogs = blogsArray.sort((a, b) => new Date(b.node.blogIntroduction.publishedAt) - new Date(a.node.blogIntroduction.publishedAt)).slice(0, 3);


    return(
      <Layout>
        <SEO title="Over mij" />
        <div className="about-us">
          <div class="wrap">
            <div className="row center-lg">
            {pageIntroduction?.thumbNail ? (
              <ContentCard horizontal size="medium" image={pageIntroduction.thumbNail.asset.url}>
                <div>
                  <h3>{pageIntroduction.title}</h3>
                  <p>{pageIntroduction.description}</p>
                  {pageIntroduction.showSocials ? (
                    <ul className="socials">
                      <li className="socials__item">
                        <a href="https://www.instagram.com/antjemachen" className="socials__link socials__link--insta"></a>
                      </li>
                      <li className="socials__item">
                        <a href="https://www.facebook.com/Antje-Machen-zwangerschapsadvies-112624847037326/" className="socials__link socials__link--facebook"></a>
                      </li>
                    </ul>
                  ) : pageIntroduction.publishedAt ?
                      (
                        <p>{pageIntroduction.publishedAt}</p>
                      ) :
                      <Link to={pageIntroduction.buttonLink}>
                        <button className='button button--secondary'>{pageIntroduction.buttonText}</button>
                      </Link>
                  }
                </div>
              </ContentCard>
            ): null}
            </div>
          </div>

          <section className="content-block">
            <div className="wrap">
              <div className="row center-lg">
                <div className="col-sm-12 col-lg-10">
                  <BasePortableText blocks={this.data.site._rawBody}/>
                </div>
              </div>
            </div>
          </section>

          <section className='blogs'>
            <div className="wrap">
              <h3>Recente Blogs</h3>
              <div className="courses__overview">
              {sortedBlogs && sortedBlogs.length > 0 && (
                sortedBlogs.map(blog => (
                  <Link to={`/blog/${blog.node.slug.current}`} className="courses__item">
                    <ContentCard size="small" image={blog.node.blogIntroduction.thumbNail.asset.url}>
                      <h3>{blog.node.title}</h3>
                      <span className="courses__date">{blog.node.blogIntroduction.publishedAt}</span>
                    </ContentCard>
                  </Link>
                ))
              )}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default OverMij
